<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useBaseExtraFieldStore from '@/stores/module/base/useBaseExtraFieldStore';

export default Vue.component('BaseResourceExtraFieldPicker', {
  emits,
  setup(_, ctx) {
    const store = useBaseExtraFieldStore();
    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      reduce: (option) => option.id,
      label: 'value',
      options: store.state,
      ...ctx.attrs,
    },
    {
      option: ({ option }) => option.value,
      'selected-option': ({ option }) => option.value,
    }));
  },
  components: { VsPickerVue },
});
</script>
